@import '~@/style/_mixin';
html,
body {
  height: 100%;
  padding: 0 !important;
}
.g-error {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  text-align: center;
  background: #fff;

  .u-img {
    width: 2.5rem;
    height: 2.5rem;
  }

  .u-text {
    margin-top: 0.28rem;
    font-size: 0.28rem;
    line-height: 0.4rem;
    color: #282828;
  }
}
